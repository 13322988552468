<template>
  <div class="bills-content">
    <div class="d-flex mb-3">
      <div class="ml-0">
        <a-range-picker
          v-model="datetime"
          format="YYYY-MM-DD"
          :allowClear="false"
          @change="updateTable"
        />
      </div>
      <div class="pl-2 ml-auto">
        <a-input-search
          v-model="searchValue"
          :placeholder="$t('placeholders.selectSearch')"
          style="width: 300px"
          allowClear
          @search="searchByInput"
        />
      </div>
    </div>
    <div class="mt-1 mb-3">
      <a-row :gutter="16" type="flex" align="bottom">
        <a-col :span="4">
          <a-statistic
            :title="`${$t('common.total')}`"
            :value="statData.count"
            style="margin-right: 50px"
          />
        </a-col>
        <a-col class="d-flex">
          <a-statistic
            :title="`${$t('common.sum')} USD`"
            :value="statData.sum_usd ? Number(statData.sum_usd).toFixed(2) : 0"
            prefix="$" style="margin-right: 50px"
          />
        </a-col>
        <a-col class="d-flex">
          <a-statistic
            :title="`${$t('common.sum')} Fee`"
            :value="statData.sum_fee ? Number(statData.sum_fee).toFixed(2) : 0"
            prefix="$" style="margin-right: 50px"
          />
        </a-col>
        <a-col class="d-flex">
          <a-statistic
            :title="`${$t('common.sum')} USD Total`"
            :value="statData.sum_usd_total ? Number(statData.sum_usd_total).toFixed(2) : 0"
            prefix="$" style="margin-right: 50px"
          />
        </a-col>
        <a-col style="margin-left: auto;" display="flex">
          <!--            <a-button-->
          <!--              :loading="reportCSVLoading"-->
          <!--              :disabled="reportCSVLoading"-->
          <!--              @click="createReportCSV"-->
          <!--              type="primary"-->
          <!--              icon="file-excel"-->
          <!--            />-->
          <a-button :loading="tableLoading" @click="refreshList" class="ml-2" icon="reload" />
        </a-col>
      </a-row>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableData"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleTableChange"
    >
      <template v-slot:id="id, record">
        <text-clipboard :text="id" />
        <text-clipboard
          v-if="record.ext_id"
          :text="record.ext_id"
          prefix="ExtID:"
          is-small
          is-grey
        />
        <time-stamp class="mt-1" :date="record.created_at" inline />
      </template>
      <template v-slot:requisites="requisites, record">
        <span v-if="record.psystem" class="d-flex align-items-center small">
          <img :src="record.psystem.img_path" width="18" height="18" class="mr-1"/> {{ record.psystem.name }}
        </span>
        <text-clipboard
          :text="record.pay_data.requisites.address"
          class="mt-1"
          is-small
          is-grey
        >
          <template #text>
            {{ record.pay_data.requisites.address.slice(0,8) }}..{{ record.pay_data.requisites.address.slice(-4) }}
          </template>
        </text-clipboard>
      </template>
      <!--        <template v-slot:created_at="created_at">-->
      <!--          {{ $moment(created_at).format('DD.MM.YY') }}-->
      <!--          <span class="small d-block text-gray-5"><a-icon-->
      <!--            type="clock-circle"/> {{ $moment(created_at).format('HH:mm') }}</span>-->
      <!--        </template>-->
      <template v-slot:client="client">
          <span v-if="client">
            <text-clipboard
              v-if="client.login"
              :text="client.login"
            />
            <text-clipboard
              v-if="client.phone"
              :text="client.phone"
              is-grey
              class="mt-1"
            >
                <template #text>
                  {{ '**' + client.phone.slice(-4) }}
                </template>
                <template #prefix>
                  <a-icon type="phone" class="text-gray-5 mr-1" />
                </template>
            </text-clipboard>
            <span v-else class="d-block text-gray-5"><a-icon type="phone"/> unavailable</span>
            <text-clipboard
              :text="client.id"
              prefix="ID:"
              class="mt-1"
              is-small
              is-grey
            />
            <text-clipboard
              :text="client.ext_id"
              prefix="ExtID:"
              class="mt-1"
              is-small
              is-grey
            />
          </span>
      </template>
      <template v-slot:amount="amount, record">
        <span class="font-weight-bolder">
          {{ record.amount_fiat }} {{ record.currency_fiat.abbr }}
        </span>
        <span class="d-block small text-gray-5">
          Fee: {{ record.fee_fiat }} {{ record.currency_fiat.abbr }}
        </span>
        <span class="d-block small text-gray-5">
          {{ amount }} {{ record.currency.abbr }}
        </span>
      </template>
      <template v-slot:status="status, record">
        <span class="mr-2 font-size-18" v-if="record.pay_data.checkup_url">
          <a-tooltip title="Transaction link" placement="bottom">
            <a :href="record.pay_data.checkup_url" target="_blank"><a-icon type="link" /></a>
          </a-tooltip>
        </span>
        <order-status :status="status" />
        <a-dropdown v-if="record.status === 'PENDING'" :trigger="['click']" class="ml-2">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="bars"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0" v-if="userCanEdit(user, 'client_transfers')">
              <a-popconfirm
                :title="$t('popups.sureToConfirmTransfer')"
                @confirm="confirmTransfer(record.id)"
              >
                <a href="javascript:">
                  <a-icon type="check" class="text-success"/>
                  Confirm payout
                </a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item key="1" v-if="userCanEdit(user, 'client_transfers')">
              <a-popconfirm
                :title="$t('popups.sureToCancelTransfer')"
                @confirm="cancelTransfer(record.id)"
              >
                <a href="javascript:">
                  <a-icon type="close" class="text-danger"/>
                  Cancel payout
                </a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import accessMix from '@/services/accessmix.js'
import TransfersService from '@/services/api/transfers/transfers.service'
import { downloadFileByLink } from '@/utils'
import TextClipboard from '../../../components/table/atomic/TextClipboard.vue'
import TimeStamp from '../../../components/table/atomic/TimeStamp.vue'
import OrderStatus from '../../../components/table/atomic/OrderStatus.vue'

const tableColumns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    width: '20%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Requisites',
    dataIndex: 'requisites',
    key: 'requisites',
    width: '20%',
    scopedSlots: { customRender: 'requisites' },
  },
  {
    title: 'User',
    dataIndex: 'client',
    key: 'client',
    width: '20%',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '15%',
    align: 'right',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'PayOutCryptoView',
  components: { OrderStatus, TimeStamp, TextClipboard },
  mixins: [accessMix],
  computed: {
    ...mapState(['user', 'projectList']),
    projectId() {
      const project = this.projectList.find(project => project.slug === 'bromoney')
      console.log('project', project)
      return project ? project.id : null
    },
  },
  data() {
    return {
      tableData: [],
      tableColumns,
      moment,
      tableLoading: false,
      pagination: { pageSize: 20 },
      searchValue: '',
      statData: {
        count: 0,
        sum_amount: 0.00,
        sum_usd: 0.00,
        sum_usd_total: 0.00,
      },
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      reportCSVLoading: false,
    }
  },
  async mounted() {
    await this.waitForProjectsToLoad()
    await this.refreshList()
    await this.getStatData()
  },
  methods: {
    async waitForProjectsToLoad() {
      return new Promise((resolve) => {
        const check = () => {
          if (this.projectList.length) {
            resolve()
          } else {
            setTimeout(check, 100)
          }
        }
        check()
      })
    },
    async confirmTransfer(id) {
      try {
        const { data } = await TransfersService.confirmTransfer(id)
        const index = this.tableData.findIndex(obj => obj.id === data.id)
        this.tableData[index] = data
        this.$message.success(`Transfer #${id} confirmed`)
        await this.refreshList()
      } catch (error) {
        this.$message.error(`Error confirm payment. Message: ${error.message}`)
      }
    },
    async cancelTransfer(id) {
      try {
        const { data } = await TransfersService.cancelTransfer(id)

        const index = this.tableData.findIndex(obj => obj.id === data.id)
        this.tableData[index] = data
        this.$message.success(`Transfer #${id} canceled`)
        await this.refreshList()
      } catch (error) {
        this.$message.error(`Error confirm payment. Message: ${error.message}`)
      }
    },
    async createReportCSV() {
      try {
        this.reportCSVLoading = true
        const { data } = await TransfersService.createReportCSV({
          project_id: this.projectId,
          from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
          to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
          search: this.searchValue,
        })
        downloadFileByLink(data.file_path)
      } catch (error) {
        console.error(`Error creating reportCSV: ${error.message}`)
        this.$notification.error({
          message: 'System can`t make report',
          description: '',
        })
      } finally {
        this.reportCSVLoading = false
      }
    },
    async getStatData() {
      try {
        const { data } = await TransfersService.getTransferAnalyticsDigits({
          project_id: this.projectId,
          from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
          to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
          search: this.searchValue,
        })

        this.statData = data
      } catch (error) {
        this.$message.error(`Error fetching statistics. Message: ${error.message}`)
      }
    },
    updateTable() {
      this.pagination.current = 1
      this.refreshList()
      this.getStatData()
    },
    async handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager

      await this.fetchTransfers({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
        search: this.searchValue,
        project_id: this.projectId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
    },
    async fetchTransfers(params = {}) {
      try {
        this.tableLoading = true
        const reqData = { results: 20, ...params }
        const { data, meta } = await TransfersService.getTransfers(reqData)
        this.tableData = data
        this.pagination = { ...this.pagination }
        this.pagination.total = meta.total
      } catch (error) {
        console.log(error)
        this.$notification.error({
          message: 'Error fetching transfers',
          description: '',
        })
      } finally {
        this.tableLoading = false
      }
    },
    async refreshList() {
      await this.fetchTransfers({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        search: this.searchValue,
        project_id: this.projectId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
    },
    async searchByInput() {
      this.pagination.current = 1
      await this.refreshList()
      await this.getStatData()
    },
  },
}
</script>
