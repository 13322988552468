import apiClient from '../../axios'

export default class TransfersService {
  static async getTransfers(params) {
    try {
      const { data } = await apiClient.get('/admin/transfers', { params })
      return data
    } catch (error) {
      console.error('Error fetching transfers:', error)
      throw error
    }
  }

  static async getTransferAnalyticsDigits(params) {
    try {
      const { data } = await apiClient.get('/admin/transfers/analytics/digits', { params })
      return data
    } catch (error) {
      console.error('Error fetching transfer analytics digits:', error)
      throw error
    }
  }

  static async getTransferStatusHistory(id) {
    try {
      const { data } = await apiClient.get(`/admin/transfers/${id}/history`)
      return data
    } catch (error) {
      console.error('Error fetching transfer status history:', error)
      throw error
    }
  }

  static async confirmTransfer(id) {
    try {
      const { data } = await apiClient.patch(`/admin/transfers/${id}/confirm`)
      return data
    } catch (error) {
      console.error('Error confirming transfer:', error)
      throw error
    }
  }

  static async cancelTransfer(id) {
    try {
      const { data } = await apiClient.patch(`/admin/transfers/${id}/cancel`)
      return data
    } catch (error) {
      console.error('Error canceling transfer:', error)
      throw error
    }
  }

  static async createReportCSV(params) {
    try {
      const { data } = await apiClient.post('/admin/transfers/analytics/report', { params })
      return data
    } catch (error) {
      console.error('Error canceling transfer:', error)
      throw error
    }
  }
}
