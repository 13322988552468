<template>
  <div class="bills-content">
    <!-- First row -->
    <div class="d-flex mb-3">
      <!-- Select agent -->
      <div>
        <agent-dropdown v-model="accountId" @input="updateTable" />
      </div>
      <!-- Select agent -->
      <!-- Select by recipients, accepted -->
      <div class="ml-3">
        <a-select v-model="visibility" class="width-150"
                  @change="updateTable">
          <a-select-option :value="'all'">
            [All Orders]
          </a-select-option>
          <a-select-option :value="'accepted_only'">
            <a-icon type="user-add" /> All Accepted
          </a-select-option>
          <a-select-option :value="'unaccepted_only'">
            <a-icon type="team" /> All Unaccepted
          </a-select-option>
        </a-select>
      </div>
      <!-- Select by recipients, accepted -->
      <!-- Select date -->
      <div class="ml-3">
        <a-range-picker
          v-model="datetime"
          format="YYYY-MM-DD"
          :allowClear="false"
          @change="updateTable"
        />
      </div>
      <!-- Select date -->
      <!-- Select search -->
      <div class="pl-2 ml-auto">
        <a-input-search
          v-model="searchValue"
          :placeholder="$t('placeholders.selectSearch')"
          style="width: 300px"
          allowClear
          @search="searchByInput"
        >
        </a-input-search>
      </div>
      <!-- Select search -->
    </div>
    <!-- First row -->
    <!-- Second row -->
    <div class="mt-1 mb-3">
      <a-row :gutter="16" type="flex" align="bottom">
        <a-col :span="4">
          <a-statistic :title="`${$t('common.total')}`" :value="statData.count"
                       style="margin-right: 50px">
          </a-statistic>
        </a-col>
        <a-col class="d-flex">
          <a-statistic :title="`${$t('common.sum')} USD`" :value="statData.sum_usd ? Number(statData.sum_usd).toFixed(2) : 0"
                       prefix="$" style="margin-right: 50px">
          </a-statistic>
          <a-statistic :title="`${$t('common.sum')} USD A`" :value="statData.sum_usd_a ? Number(statData.sum_usd_a).toFixed(2) : 0"
                       prefix="$" style="margin-right: 50px">
          </a-statistic>
          <a-statistic :title="`${$t('common.sum')}`" :value="amountOfCurrencies"
                       style="margin-right: 50px">
          </a-statistic>
        </a-col>
        <a-col style="margin-left: auto;" display="flex">
          <!-- Export button -->
          <a-button
            :loading="reportCSVLoading"
            :disabled="reportCSVLoading"
            @click="createReportCSV"
            icon="file-excel"
            type="primary"
          />
          <!-- Export button -->
          <!-- Reload button -->
          <a-button :loading="paymentsTableLoading" @click="refreshList" class="ml-2" icon="reload" />
          <!-- Reload button -->
        </a-col>
      </a-row>
    </div>
    <!-- Second row -->
    <a-table
      :columns="paymentsColumns"
      :dataSource="paymentsData"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="paymentsTableLoading"
      @change="handleTableChange"
    >
      <template v-slot:id="id, record">
        <text-clipboard :text="id" />
        <time-stamp class="mt-1" :date="record.created_at" inline />
      </template>
      <template v-slot:status="status, record">
        <!--          {{ status }}-->
        <a-tooltip
          :title="`(${record.chat.msg_count}) ${$t('common.messages')}${$t('pre.in')}${$t('common.chat')}`"
          placement="bottom" v-if="record.chat && record.chat.msg_count">
          <!--            <i class="fe fe-message-circle font-size-18 text-primary" />-->
          <a href="javascript:" @click="openChatModal(record.chat)" class="mr-2">
            <a-icon type="wechat" class="text-primary font-size-18"/>
            <sup class="">{{ record.chat.msg_count }}</sup></a>
        </a-tooltip>
        <order-status
          :status="status"
          :title="getOrderStatusTitle(status, record)"
        />
        <a-dropdown :trigger="['click']" class="ml-2">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="bars"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0" v-if="userCanEdit(user, 'sales') && record.accepted_at !== null && record.status !== 'CONFIRMED'">
              <a-popconfirm
                :title="`${$t('popups.sureToConfirmPayment')} #${record.id}?`"
                @confirm="confirmPayment(record.id)"
              >
                <a href="javascript:">
                  <a-icon type="check" class="text-success"/>
                  Confirm payment</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item key="1" v-if="userCanEdit(user, 'sales') && record.accepted_at !== null && record.status !== 'CANCELED'">
              <a-popconfirm
                :title="`${$t('popups.sureToCancelPayment')} #${record.id}?`"
                @confirm="cancelPayment(record.id)"
              >
                <a href="javascript:">
                  <a-icon type="close" class="text-danger"/>
                  Cancel payment</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-divider v-if="userCanEdit(user, 'sales') && record.accepted_at !== null && record.status !== 'CANCELED' && record.status !== 'CONFIRMED'"/>
            <a-menu-item key="2" v-if="userCanView(user, 'sale.history')">
              <a href="javascript:" @click="showStatusHistory(record.id)">
                <a-icon type="build"/>
                Status Timeline</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <span v-if="paymentsLoading" class="ml-2"><a-icon type="loading"/></span>
      </template>
      <template v-slot:account="account">
        <span v-if="account">
          <span class="mr-1">
            <agent-avatar :agent="account" />
          </span>
          {{ account.name }}
        </span>
      </template>
      <template v-slot:requisites="requisites, record">
        <span v-if="record.psystem" class="small">
          <img :src="record.psystem.img_path" width="18" height="18"/> {{ record.psystem.name }}
        </span>
        <span v-if="requisites" class="d-block small text-gray-5">{{ requisites.address }}</span>
      </template>
      <!--        <template v-slot:created_at="created_at">-->
      <!--          {{ $moment(created_at).format('DD.MM.YY') }}-->
      <!--          <span class="small d-block text-gray-5"><a-icon-->
      <!--            type="clock-circle"/> {{ $moment(created_at).format('HH:mm') }}</span>-->
      <!--        </template>-->
      <template v-slot:client="client">
        <p class="mb-0">{{ client.login }}</p>
        <p class="mb-0 d-block small text-gray-5">id: {{ client.id }}</p>
        <span v-if="client.short_name" class="mt-1 d-block small text-gray-5">
          {{ client.short_name }}
        </span>
      </template>
      <template v-slot:amount="amount, record">
        <span class="font-weight-bolder">
          {{ amount }} {{ record.currency.abbr }}
        </span>
        <span v-if="record.status === 'CONFIRMED'" class="pr-1">
          <a-icon type="arrow-up" class="text-success"/>
        </span>
        <div>
          <a-tooltip :title="`Client rate: ${record.rate}`" placement="bottom">
            <span class="d-block small text-gray-5">{{ record.amount_usd }} $</span>
          </a-tooltip>
          <a-tooltip :title="`Agent rate: ${record.rate_a}`" placement="bottom">
            <span class="d-block small text-gray-5">{{ record.amount_usd_a }} $ <agent-avatar :agent="record.agent" :icon-size="12" /></span>
          </a-tooltip>
        </div>
      </template>
      <template v-slot:agent_time="agent_time">
        <span v-if="agent_time" class="small">
          {{ agent_time }}<span class="ml-1">min.</span>
        </span>
      </template>
    </a-table>
    <a-modal v-model="paymentChatModal"
             :destroyOnClose="true"
             title="Client-Agent Chat"
             :footer="null"
             class="chat-modal"
             width="680px"
             height="680px"
    >
      <agent-chat-modal
        :chat-obj="paymentChat"
      />
    </a-modal>
    <a-modal v-model="paymentStatusHistoryModal"
             :destroyOnClose="true"
             :title="currentPaymentId ? `Payment ${currentPaymentId} status timeline` : 'Payment status timeline'"
             :footer="null"
             class="history-modal"
             width="480px"
             height="520px"
    >
      <payment-status-history-modal
        :payment-id="currentPaymentId"
      />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import accessMix from '@/services/accessmix.js'
import agentChatModal from '@/components/client-agent-chat/AgentChatModal.vue'
import agentAvatar from '@/components/agent/AgentAvatar.vue'
import paymentStatusHistoryModal from '@/views/bills/paymentStatusHistoryModal.vue'
import apiClient from '@/services/axios'
import SalesService from '@/services/api/sales/sales.service'
import AgentDropdown from '@/components/agent/AgentDropdown.vue'
import TextClipboard from '../../../components/table/atomic/TextClipboard.vue'
import TimeStamp from '../../../components/table/atomic/TimeStamp.vue'
import OrderStatus from '../../../components/table/atomic/OrderStatus.vue'

const paymentsColumns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'User',
    dataIndex: 'client',
    key: 'client',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'Agent',
    dataIndex: 'agent',
    key: 'agent',
    scopedSlots: { customRender: 'account' },
  },
  {
    title: 'Requisites',
    dataIndex: 'requisites',
    key: 'requisites',
    width: '18%',
    scopedSlots: { customRender: 'requisites' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Time',
    dataIndex: 'agent_time_spent',
    key: 'agent_time_spent',
    width: '100px',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'agent_time' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '15%',
    align: 'right',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'PayOutFiatView',
  mixins: [accessMix],
  components: {
    OrderStatus,
    TimeStamp,
    TextClipboard,
    AgentDropdown,
    agentChatModal,
    agentAvatar,
    paymentStatusHistoryModal,
  },
  computed: {
    ...mapState(['user', 'projectList']),
    projectId() {
      if (!this.projectList || !this.projectList.length) return null
      const project = this.projectList.find(p => p.slug === 'bromoney')
      return project ? project.id : null
    },
    /**
     * This code calculates amount of all currencies without separating, just because
     * @returns {T|number}
     */
    amountOfCurrencies() {
      if (typeof this.statData.currencies !== 'undefined') {
        return this.statData.currencies.reduce((acc, currency) => acc + currency.amount, 0)
      }
      return 0
    },
  },
  data() {
    return {
      paymentsData: [],
      paymentsColumns,
      moment,
      paymentsLoading: false,
      paymentsTableLoading: false,
      pagination: { pageSize: 20 },
      searchField: 'id',
      searchValue: '',
      paymentChatModal: false,
      paymentChat: null,
      paymentWebhooksModal: false,
      paymentStatusHistoryModal: false,
      accountId: 0,
      currentPaymentId: undefined,
      statData: {
        count: 0,
        sum_amount: 0,
        sum_usd: 0,
      },
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      reportCSVLoading: false,
      visibility: 'all',
    }
  },
  async mounted() {
    await this.waitForProjectsToLoad()
    await this.refreshList()
    await this.getStatData()
  },
  methods: {
    async waitForProjectsToLoad() {
      return new Promise((resolve) => {
        const check = () => {
          if (this.projectList.length) {
            resolve()
          } else {
            setTimeout(check, 100)
          }
        }
        check()
      })
    },
    getOrderStatusTitle(status, record) {
      switch (status) {
        case 'ACCEPTED':
          return 'Awaiting payment'
        case 'COMPLETED':
        case 'CONFIRMED':
          return 'Confirmed'
        case 'EXPIRED':
          return 'Expired'
        case 'CASHED':
          return 'Cashed by Agent'
        case 'ONAPPEAL':
          return 'On appeal'
      }
    },
    async createReportCSV() {
      try {
        this.reportCSVLoading = true
        const url = '/admin/sales/analytics/report'
        const response = await apiClient.post(url, {
          agent_id: this.accountId,
          project_id: this.projectId,
          from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
          to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
          search: this.searchValue,
        })
        setTimeout(() => {
          const fileLink = document.createElement('a')
          fileLink.setAttribute('href', response.data.data.file_path)
          fileLink.setAttribute('download', response.data.data.file_path.replace(/^.*[\\/]/, ''))
          document.body.appendChild(fileLink)
          fileLink.click()
          document.body.removeChild(fileLink)
        }, 0)
      } catch (e) {
        this.$notification.error({
          message: 'System can`t make report',
          description: '',
        })
      } finally {
        this.reportCSVLoading = false
      }
    },
    async getStatData() {
      try {
        const url = '/admin/sales/analytics/digits'

        const response = await apiClient.get(url, {
          params: {
            agent_id: this.accountId,
            project_id: this.projectId,
            from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
            to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
            search: this.searchValue,
          },
        })
        this.statData = response.data.data
      } catch (e) {
      }
    },
    updateTable() {
      this.pagination.current = 1
      this.refreshList()
      this.getStatData()
    },
    handleTableChange(pagination, filters, sorter) {
      // console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
        search: this.searchValue,
        project_id: this.projectId,
        agent_id: this.accountId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
    },
    async fetch(params = {}) {
      // console.log('params:', params)
      this.paymentsTableLoading = true
      const reqData = {
        results: 20,
        visibility: this.visibility,
        ...params,
      }
      return SalesService.getClientSales(reqData).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.paymentsTableLoading = false
        this.paymentsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.paymentsTableLoading = false
      })
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        search: this.searchValue,
        project_id: this.projectId,
        agent_id: this.accountId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
      // console.log(this.pagination)
    },
    async searchByInput(value) {
      this.pagination.current = 1
      this.refreshList()
      this.getStatData()
    },
    async confirmPayment(paymentId) {
      // ----
      return SalesService.confirmClientSale(paymentId).then((response) => {
        const tData = response.data.data
        const index = this.paymentsData.findIndex(obj => obj.id === tData.id)
        this.paymentsData[index] = tData
        this.$message.success(`Payment #${paymentId} confirmed`)
        this.refreshList()
      }).catch(error => {
        this.$message.error(`Error confirm payment. Message: ${error.message}`)
      })
    },
    async cancelPayment(paymentId) {
      // ----
      return SalesService.cancelClientSale(paymentId).then((response) => {
        const tData = response.data.data
        const index = this.paymentsData.findIndex(obj => obj.id === tData.id)
        this.paymentsData[index] = tData
        this.$message.success(`Payment #${paymentId} canceled`)
        this.refreshList()
      }).catch(error => {
        this.$message.error(`Error canceling payment. Message: ${error.message}`)
      })
    },
    openChatModal(chatObj) {
      this.paymentChat = chatObj
      this.paymentChatModal = true
    },
    showWebhooks(paymentId) {
      this.currentPaymentId = paymentId
      this.paymentWebhooksModal = true
    },
    showStatusHistory(paymentId) {
      this.currentPaymentId = paymentId
      this.paymentStatusHistoryModal = true
    },
  },
}
</script>
