<template>
  <div>
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <span class="h5 mb-0">
          <font-awesome-icon
            icon="money-check"
            class="mt-1 mr-1 font-size-24"/> Pay out
        </span>
        <div class="m-auto">
          <a-radio-group v-model="targetType" button-style="solid">
            <a-radio-button value="fiat">
              <a-icon type="credit-card" theme="filled" /> Fiat
            </a-radio-button>
            <a-radio-button value="crypto">
              <a-icon type="dollar-circle" theme="filled" /> Crypto
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <ADivider />
      <KeepAlive>
        <PayOutFiatView v-if="targetType === 'fiat'"/>
        <PayOutCryptoView v-else />
      </KeepAlive>
    </a-card>
  </div>
</template>
<script>
import PayOutFiatView from './PayOutFiatView.vue'
import PayOutCryptoView from './PayOutCryptoView.vue'

export default {
  name: 'PayOutView',
  components: { PayOutCryptoView, PayOutFiatView },
  data() {
    return {
      targetType: 'fiat',
    }
  },
}
</script>

<style scoped lang="scss">

</style>
